@import 'src/styles/utils';
@import 'src/styles/palette';
@import 'src/styles/spaces';
@import 'src/styles/typography';

.button {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border: 0;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }

  &--width-fit {
    width: fit-content;
  }

  &--width-expand {
    width: 100%;
  }

  &--width-auto {
    width: auto;
  }
}

.button--primary {
  @include small('bold');

  padding: 0 16px;
  border-radius: 4px;
  color: $white;
  text-transform: uppercase;
  gap: 10px;

  /* size */
  &.button--large {
    height: 56px;
  }

  &.button--small {
    height: 52px;
  }

  /* red */
  &.button--red {
    background-color: $red-500;
  }

  &.button--red:hover {
    background-color: $red-400;
  }

  &.button--red:active {
    background-color: $red-600;
  }

  &.button--red:disabled {
    background-color: $grey-300;
  }

  /* blue */
  &.button--blue {
    background-color: $blue-600;
  }

  &.button--blue:hover {
    background-color: $blue-500;
  }

  &.button--blue:active {
    background-color: $blue-700;
  }

  &.button--blue:disabled {
    background-color: $grey-300;
  }
}

.button--secondary {
  @include small('bold');

  padding: 0 16px;
  border-radius: 4px;
  background-color: $white;
  text-transform: uppercase;
  gap: 10px;

  /* size */
  &.button--large {
    height: 56px;
  }

  &.button--small {
    height: 52px;
  }

  /* red */
  &.button--red {
    border: 1px solid $red-500;
    color: $red-500;
  }

  &.button--red:hover {
    border: 1px solid $red-400;
    color: $red-400;
  }

  &.button--red:active {
    border: 1px solid $red-600;
    color: $red-600;
  }

  &.button--red:disabled {
    border: 1px solid $grey-300;
    color: $grey-300;
  }

  /* blue */
  &.button--blue {
    border: 1px solid $blue-600;
    color: $blue-600;
  }

  &.button--blue:hover {
    border: 1px solid $blue-500;
    color: $blue-400;
  }

  &.button--blue:active {
    border: 1px solid $blue-700;
    color: $blue-600;
  }

  &.button--blue:disabled {
    border: 1px solid $grey-300;
    color: $grey-300;
  }
}

.button--tertiary {
  @include body;

  border-radius: 4px;

  /* size */
  &.button--large {
    height: 52px;
    padding: 0 16px;
    gap: 12px;
  }

  &.button--small {
    min-height: 40px;
    padding: 0 8px;
    gap: 5px;
  }

  /* grey */
  &.button--grey {
    background-color: $blue-100;
    color: $blue-600;
  }

  &.button--grey:hover {
    background-color: $grey-100;
    color: $blue-600;
  }

  &.button--grey:active {
    background-color: $grey-200;
    color: $blue-600;
  }

  &.button--grey:disabled {
    background-color: $grey-100;
    color: $grey-300;
  }

  /* white */
  &.button--white {
    background-color: $white;
    color: $blue-600;
  }

  &.button--white:hover {
    background-color: $grey-100;
    color: $blue-600;
  }

  &.button--white:active {
    background-color: $white;
    color: $blue-600;
  }

  &.button--white:disabled {
    background-color: $white;
    color: $grey-300;
  }

  /* only icon */
  &.button--only-icon {
    width: 56px;
    height: 52px;
  }
}
