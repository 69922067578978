@import '../../../styles/utils';
@import '../../../styles/palette';
@import '../../../styles/global';
@import '../../../styles/spaces';

.label {
  display: flex;
  align-items: center;
  width: 100%;
}

.container {
  width: 100%;
  height: fit-content;

  &--expand {
    width: 100%;
  }

  &--fit {
    width: fit-content;
  }

  &--auto {
    width: auto;
  }
}

.inputContainer {
  display: flex;
  position: relative;
  align-items: center;
  height: 56px;
}

.eyeButton {
  right: grid_units(2);
  cursor: pointer;
}

.icon {
  position: absolute;
  z-index: 1;
  transition: all 250ms ease;
  background-color: transparent;
}

.right {
  right: grid_units(2);
}

.left {
  left: grid_units(2);
}

.button {
  position: absolute;
  z-index: 1;
  transition: all 250ms ease;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: grid_units(2);
  border: solid 1px $blue-300;
  border-radius: grid_units(0.5);
  color: $blue-600;
  font-family: $main-font-family;
  font-size: 16px;
  font-weight: 300;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }

  & ~ .eyeButton {
    color: $blue-300;
  }

  & ~ button {
    & > .iconButton {
      color: $blue-300;
    }

    &:hover > .iconButton {
      color: $blue-600;
    }
  }

  &::placeholder {
    transition: all 250ms ease;
    color: $blue-200;
  }

  &:focus,
  &:hover {
    border-color: $blue-400;

    & ~ .eyeButton {
      color: $blue-600;
    }

    & ~ button > .iconButton {
      color: $blue-600;
    }

    &::placeholder {
      color: $blue-400;
    }
  }

  &:disabled {
    border-color: $blue-100;
    background-color: $blue-100;
    box-shadow: none;
    cursor: not-allowed;

    & ~ .icon,
    & ~ button > .iconButton {
      color: $blue-200;
    }

    & ~ .eyeButton {
      cursor: not-allowed;
    }

    &::placeholder {
      color: $blue-200;
    }
  }

  &:read-only {
    border-color: transparent;
    background-color: $grey-100;
  }
}

.error {
  .input {
    border-color: $red-500;
  }
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  box-shadow: 0 0 0 $spaces-6 white inset !important;
  -webkit-text-fill-color: $blue-600 !important;
}

.leftIconPadding {
  padding-left: grid_units(6);
}

.rightIconPadding {
  padding-right: grid_units(6);
}

.errorMessage {
  position: absolute;
}
