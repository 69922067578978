:root {
  --white: #fff;
  --black: #000;
  --red-100: #ffece9;
  --red-200: #ffc5be;
  --red-300: #ff8c7d;
  --red-400: #ff6552;
  --red-500: #ff3f27;
  --red-600: #cc321f;
  --red-700: #992617;
  --red-800: #661910;
  --red-900: #330d08;
  --blue-100: #eaecef;
  --blue-200: #c0c7ce;
  --blue-300: #96a2ad;
  --blue-400: #6c7c8c;
  --blue-500: #42576b;
  --blue-600: #2d445a;
  --blue-700: #243648;
  --blue-800: #1b2936;
  --blue-900: #0d141b;
  --burgundy-100: #f5eaed;
  --burgundy-200: #e0bfc9;
  --burgundy-300: #c17f93;
  --burgundy-400: #ac546f;
  --burgundy-500: #97294b;
  --burgundy-600: #79213c;
  --burgundy-700: #5b192d;
  --burgundy-800: #3c101e;
  --burgundy-900: #2d0c16;
  --grey-100: #f6f6f6;
  --grey-200: #e3e3e5;
  --grey-300: #d1d1d4;
  --grey-400: #bebec3;
  --grey-500: #a2a2a9;
  --grey-600: #828287;
  --grey-700: #515155;
  --grey-800: #313133;
  --grey-900: #202022;
  --accent-green: #96e99f;
  --accent-green-100: #eefffa;
  --accent-green-200: #dbfff5;
  --accent-green-300: #bbf6e5;
  --accent-green-400: #82edcd;
  --accent-green-500: #36d1a4;
  --accent-green-600: #2ba783;
  --accent-green-700: #207d62;
  --accent-green-800: #165442;
  --accent-green-900: #0b2a21;
  --accent-yellow: #ffe288;
  --accent-yellow-100: #fff9e7;
  --accent-yellow-200: #ffeeb8;
  --accent-yellow-300: #ffe288;
  --accent-yellow-400: #ffd658;
  --accent-yellow-500: #ffc511;
  --accent-yellow-600: #e6b10f;
  --accent-yellow-700: #b38a0c;
  --accent-yellow-800: #806309;
  --accent-yellow-900: #332703;
  --accent-orange: #ffc499;
}
