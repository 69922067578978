@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.container {
  padding: grid_units(4);
}

.deedsSignDateContainer {
  display: flex;
  justify-content: flex-start;
  gap: grid_units(4);
  margin: grid_units(4) 0;
}

.fileInput {
  margin-top: grid_units(4);
}

.checkbox {
  margin-top: grid_units(4);
}

.divider {
  width: 100%;
  margin: grid_units(4) 0;
  border: 1px $blue-100 solid;
}

.dateInput {
  input {
    width: 402px;
  }
}

.timeInputContainer {
  max-width: 178px;
}

.timeInput {
  max-width: 178px;
  display: flex;
  justify-content: space-between;
  button {
    width: 178px;
    &:focus,
    &:hover {
      border-color: $blue-400 !important;
    }
  }
}
