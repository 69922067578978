@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: grid_units(13);
  height: grid_units(3);
  border: none;
  border-radius: grid_units(0.5);
  color: $white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.46px;
  text-align: center;
  text-transform: uppercase;
}

.available {
  display: none;
}

.booked {
  background-color: $blue-600;
}

.sold {
  background-color: $burgundy-500;
}

.specialOffer {
  width: max-content;
  padding: 0 grid_units(1);
  background-color: $burgundy-500;
}
