@import 'src/styles/palette.scss';
@import 'src/styles/utils.scss';
@import 'src/styles/global';

.container {
  box-sizing: border-box;
  max-width: 572px;
}

.imageContainer {
  display: flex;
  box-sizing: inherit;
  flex-direction: column;
  height: 176px;
  margin-right: auto;
  margin-left: auto;
}

.error {
  box-sizing: inherit;
  border: 1px $red-500 solid;
  background-color: $red-100;

  &::placeholder {
    color: $red-500;
  }
}
