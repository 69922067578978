@import '../../../styles/utils';
@import '../../../styles/palette';

.container {
  position: relative;

  &--expand {
    width: 100%;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label {
  margin-left: grid_units(1);

  a {
    color: inherit;
  }

  user-select: none;
}

.square {
  display: flex;
  width: grid_units(2);
  height: grid_units(2);
  transition: all 200ms ease;
  border: solid 1px $blue-300;
  border-radius: 2px;
  background-color: $white;
}

.checkMark {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease;
  opacity: 0;
  color: $white;
}

.checkboxInput {
  display: none;

  &:hover ~ .labelContainer {
    .square {
      border-color: $blue-600;
    }
  }

  &:checked ~ .labelContainer {
    .square {
      border-color: $blue-600;
      background-color: $blue-600;
    }

    .checkMark {
      opacity: 1;
    }
  }

  &:disabled ~ .labelContainer {
    cursor: not-allowed;

    .square {
      border-color: $blue-100;
      background-color: $blue-100;
    }

    .label {
      color: $blue-100;
    }
  }
}
