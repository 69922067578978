@import '../../../styles/utils';
@import '../../../styles/palette';

.container {
  padding-bottom: grid_units(1);
}

.warningTextStyle {
  padding-left: 4px;
  color: $blue-200;
}
