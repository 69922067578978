@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.ownerDataTitle {
  margin-top: $spaces-5;
}

.container {
  & > div {
    margin-top: grid_units(2);
  }
}

.documentButtonsContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: grid_units(2);
}

.spaceBottom {
  margin-bottom: $spaces-2;
}
