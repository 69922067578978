:root {
  --spaces-1: 8px;
  --spaces-2: 16px;
  --spaces-3: 24px;
  --spaces-4: 32px;
  --spaces-5: 40px;
  --spaces-6: 48px;
  --spaces-8: 56px;
  --spaces-7: 64px;
  --spaces-10: 80px;
  --spaces-11: 88px;
}
