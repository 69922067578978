@import 'src/styles/palette';
@import 'src/styles/utils';

.common {
  color: $blue-600;
}

.h1Title {
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 48px;

  @include screenIn(desktop) {
    font-size: 60px;
    line-height: 60px;
  }
}

.h2Title {
  font-size: 34px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 48px;

  em {
    color: $red-500;
  }

  @include screenIn(desktop) {
    font-size: 42px;
  }
}

.h3Title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 30px;

  em {
    color: $red-500;
  }

  @include screenIn(desktop) {
    font-size: 28px;
    line-height: 34px;
  }
}

.headline {
  font-size: 20px;
  line-height: 30px;

  strong {
    font-weight: bold;
  }
}

.paragraphLead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 30px;

  @include screenIn(desktop) {
    font-size: 20px;
  }

  strong {
    font-weight: bold;
  }

  em {
    color: $red-500;
  }
}

.paragraph {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 25px;

  strong {
    font-weight: bold;
  }
}

.smallParagraph {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 20px;

  strong {
    font-weight: bold;
  }
}

.footNote {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: -0.2px;

  strong {
    font-weight: bold;
  }
}

.center {
  text-align: center;
}
