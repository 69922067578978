@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';

.container {
  padding: grid_units(4);
}

.ownersContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: grid_units(1);
  margin-top: grid_units(2);
}

.ownerCard {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  max-width: 480px;
  padding: $spaces-2 $spaces-3;
  border-radius: 8px;
  background-color: $grey-100;
}

.circleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-right: $spaces-3;
  border-radius: 50%;
  background-color: $blue-600;
}

.ownerName {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: auto;
}

.arrowIcon {
  align-self: center;
  justify-self: flex-end;
}

.ownerDataTitle {
  margin: grid_units(2) 0;
}

.divider {
  width: 100%;
  margin: grid_units(4) 0;
  border: 1px $blue-100 solid;
}
