@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';

.labelOwnerInformationContainer > div {
  margin-top: grid_units(2);
}

.ownerDataTitle {
  margin-top: $spaces-3;
}
