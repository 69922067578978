@import 'src/styles/utils';
@import 'src/styles/palette';
@import 'src/styles/global';
@import 'src/styles/spaces';

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  margin-left: auto;
  padding: 0;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;
}
