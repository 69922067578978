@import 'src/styles/utils.scss';

@mixin h1desktop {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 66px;

  strong {
    font-weight: bold;
  }
}

@mixin h1mobile {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48px;

  strong {
    font-weight: bold;
  }
}

@mixin h1 {
  @include isDevice(desktop) {
    @include h1desktop;
  }
  @include isDevice(tablet) {
    @include h1desktop;
  }
  @include isDevice(mobile) {
    @include h1mobile;
  }
}

@mixin h2desktop {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48px;

  strong {
    font-weight: bold;
  }
}

@mixin h2mobile {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;

  strong {
    font-weight: bold;
  }
}

@mixin h2 {
  @include isDevice(desktop) {
    @include h2desktop;
  }
  @include isDevice(tablet) {
    @include h2desktop;
  }
  @include isDevice(mobile) {
    @include h2mobile;
  }
}

@mixin h3desktop {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 34px;

  strong {
    font-weight: bold;
  }
}

@mixin h3mobile {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;

  strong {
    font-weight: bold;
  }
}

@mixin h3 {
  @include isDevice(desktop) {
    @include h3desktop;
  }
  @include isDevice(tablet) {
    @include h3desktop;
  }
  @include isDevice(mobile) {
    @include h3mobile;
  }
}

@mixin headline {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
}

@mixin body {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;

  strong {
    font-weight: bold;
  }
}

@mixin small($weight: 'regular') {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;

  @if ($weight == 'regular') {
    font-weight: 400;
  }
  @if ($weight == 'bold') {
    font-weight: 700;
  }

  strong {
    font-weight: bold;
  }
}

@mixin mini {
  font-family: Apercu, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;

  strong {
    font-weight: bold;
  }
}
