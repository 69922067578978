@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.ownerDataTitle {
  margin-top: $spaces-5;
}

.container {
  margin-top: grid_units(2);

  & > div {
    margin-top: grid_units(2);
  }
}

.labelOwnerInformationContainer {
  max-width: 50%;
  margin-top: grid_units(2);
  padding: grid_units(2);
  border-radius: 8px;
  background-color: $grey-100;
}

.documentPreview {
  max-height: 200px;
  margin: grid_units(2) 0;
}

.imageLinkContainer {
  & > a {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
  }
}
