@import '../../../styles/utils';
@import '../../../styles/palette';
@import '../../../styles/spaces';

.slider {
  :global {
    .slick-dots {
      position: relative;
      bottom: 0;
      margin-top: grid_units(4);

      li {
        width: grid_units(2);
        height: grid_units(2);

        & + & {
          margin-left: grid_units(2);
        }

        button {
          width: grid_units(2);
          height: grid_units(2);
          margin: 0;
          padding: 0;
          border-radius: grid_units(2);
          background-color: $blue-200;

          &::before {
            content: none;
          }
        }

        &.slick-active button {
          background-color: $blue-600;
        }
      }
    }
    .slick-slide > div {
      display: grid;
      place-items: center;
    }
  }
}

.sliderSlickSlide100 {
  :global {
    .slick-slide {
      width: 100%;
    }
  }
}

.slide {
  display: flex;
  justify-content: center;
}

.sliderButton {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  align-items: center;
  justify-content: center;
  padding: $spaces-1;
  transform: translateY(-50%);
  border: 0;
  border-radius: 100%;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    .sliderButtonIcon {
      opacity: 0.6;
    }
  }
  @include screenIn(desktop) {
    display: inline-flex;
  }
}

.prevButton {
  @include screenIn(desktop) {
    left: -50px;
  }
}
.nextButton {
  @include screenIn(desktop) {
    right: -50px;
  }
}
