@import 'src/styles/typography.scss';

.text {
  strong {
    font-weight: 700;
  }

  &-h1 {
    @include h1;
  }

  &-h2 {
    @include h2;
  }

  &-h3 {
    @include h3;
  }

  &-headline {
    @include headline;
  }

  &-body {
    @include body;
  }

  &-small {
    @include small;
  }

  &-mini {
    @include mini;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-normal {
    font-weight: 400;
  }

  &-bold {
    font-weight: 700;
  }
}

.textDecoration {
  &-underline {
    text-decoration: underline;
  }

  &-none {
    text-decoration: none;
  }
}

.display {
  &-block {
    display: block;
  }

  &-inline {
    display: inline;
  }

  &-inlineBlock {
    display: inline-block;
  }

  &-none {
    display: none;
  }
}
