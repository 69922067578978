@import '../../../styles/utils';
@import '../../../styles/palette';
@import '../../../styles/global';
@import '../../../styles/spaces';

.container {
  position: relative;
}

.label {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
}

.inputContainer {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}

.icon {
  position: absolute;
  right: $spaces-2;
}

.input {
  position: relative;
  width: 100%;
  background-color: #fff;

  input {
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    padding: grid_units(2);
    padding-right: 48px;
    transition: all 250ms ease;
    border: solid 1px $blue-300;
    border-radius: grid_units(0.5);
    background-color: transparent;
    color: $blue-600;
    font-family: $main-font-family;
    font-size: 16px;
    font-weight: 300;

    &::placeholder {
      transition: all 250ms ease;
      color: $blue-200;
    }

    &:hover {
      border-color: $blue-400;

      &::placeholder {
        color: $blue-400;
      }
    }

    &:disabled {
      border-color: $blue-100;
      background-color: $blue-100;
      box-shadow: none;
      cursor: not-allowed;

      &::placeholder {
        color: $blue-200;
      }
    }
  }
}

.error .input input {
  border-color: $red-500;
  background-color: $red-100;

  &::placeholder {
    color: $red-500;
  }
}

.errorMessage {
  max-width: 100%;
  padding-top: grid_units(0.5);
  padding-left: 1px;
  color: $red-500;
}

.border {
  z-index: 10;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 310px;
  padding-top: 2px;
  border: solid 1px $blue-200;
  border-radius: 4px;
  background-color: white;
  @include screenIn(mobile) {
    width: 100%;

    :global {
      .DayPicker-Month {
        width: 100%;
        font-size: 16px !important;
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  @include screenIn(mobile) {
    width: 100%;

    > div {
      width: 100%;
    }
  }
}

.errorMessage {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

.customCell {
  background-color: '#FFFFFF ';
  color: #2d445a;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
    border-radius: 4px;
  }
}

.customHead {
  color: #2d445a;
}
.customCaption {
  display: flex;
  width: 100%;
  color: #2d445a;
  justify-content: space-between;

  & > * {
    border: 1px solid #96a2ad;
    border-radius: 4px;
    font-family: 'Apercu';
    font-style: normal;
    padding: 4px 12px;
    margin: 4px 8px;
  }
}
.customCaptionLabel {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 4px;

  & > * {
    padding-left: 8px;
  }
}
