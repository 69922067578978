@import 'src/styles/utils';
@import 'src/styles/palette';
@import 'src/styles/spaces';

.container {
  display: flex;
  padding: grid_units(2);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: grid_units(0.5);
  background-color: $grey-100;
  box-shadow: none;

  &.isDragActive {
    box-shadow: 0 0 6px 0 rgba(45, 68, 90, 0.32);
  }

  &.isDragReject {
    box-shadow: 0 0 6px 0 rgba(255, 63, 39, 0.32);
  }
}

.dropzone {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: grid_units(0.5);
  background-color: $grey-100;
}

.titleText {
  color: $blue-600;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: $spaces-2;
  margin-bottom: $spaces-1;
}

.subtitleText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $blue-600;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.textsContainer {
  padding-right: grid_units(5);
  padding-left: grid_units(5);
}

.roundIconPass {
  background-color: $accent-green-400;
}
.roundIconReject {
  background-color: $red-500;
}
