@import '../../../styles/palette';

.red {
  fill: $red-500;
  color: $red-500;
}

.blue {
  fill: $blue-600;
  color: $blue-600;
}

.blueLight {
  fill: $blue-300;
  color: $blue-300;
}

.grey {
  fill: $grey-600;
  color: $grey-600;
}

.greyLight {
  fill: $grey-300;
  color: $grey-300;
}

.burgundy {
  fill: $burgundy-600;
  color: $burgundy-600;
}

.white {
  fill: $white;
  color: $white;
}

.black {
  fill: $black;
  color: $black;
}

.green {
  fill: $accent-green-600;
  color: $accent-green-600;
}

.yellow {
  fill: $accent-yellow-600;
  color: $accent-yellow-600;
}

.bold {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}
