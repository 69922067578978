@import '../styles/palette';
@import '../styles/spaces';
@import '../styles/utils';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/**
 * Use if we have any global rules
 */

/**
html,
body {
  overflow-x: hidden;
}
 */

$main-font-family: apercu, sans-serif;

.cookie-wrapper-button {
  display: flex;
  flex-flow: column wrap;
  align-self: center;
  justify-content: center;
  width: 100%;

  @include screenIn(desktop) {
    flex-flow: row nowrap;
    align-items: center;
    width: auto;
    min-width: grid_units(70);
    min-height: 64px;
  }
}

.cookie-link {
  color: $blue-600;
  text-decoration: underline;
}
