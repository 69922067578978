@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';

.content {
  position: fixed;
  z-index: 16;
  top: 63px;
  right: 0;
  width: 50vw;
  height: calc(95.5vh - 64px);
  padding: $spaces-3 $spaces-3;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 4px 24px rgb(0 0 0 / 12%);
}

.overlay {
  background: rgba(0 0 0 / 20%);
}

.closeButton {
  all: unset;
  position: fixed;
  z-index: 32767;
  top: 88px;
  right: 24px;
  box-sizing: border-box;
  cursor: pointer;
}

/* TODO: Handle animation on open and close
// .inactive {
//   left: 100vw;
//   transition: left 0.3s ease-in;
// }

// .active {
//   // left: 48vw;
//   // transition: left 0.3s ease-in;
// }
*/
