@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 554px;
  padding: grid_units(4);
  background-color: $grey-100;
  gap: grid_units(2);
}

.roundIcon {
  background-color: #2ba783;
  width: 24px;
  height: 24px;
}

.replaceFile {
  text-decoration: underline;
  cursor: pointer;
  & > p {
    color: $blue-600;
    font-weight: 700;

    &:hover {
      color: $blue-400 !important;
      text-decoration: underline;
    }

    &:active {
      color: $blue-700;
      text-decoration: underline;
    }
  }
}

.viewFile {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  & > a {
    color: $blue-600;
    font-weight: 700;

    &:hover {
      color: $blue-400 !important;
      text-decoration: underline;
    }

    &:active {
      color: $blue-700;
      text-decoration: underline;
    }
  }
}
