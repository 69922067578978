@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.container {
  padding: grid_units(4);
  padding-bottom: calc(grid_units(4) + 100px);
}
.datesInput {
  max-width: 319px;
}

.deedsSignDateContainer {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  margin: grid_units(4) 0;
  gap: grid_units(4);
}

.smartHousingDateContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  margin: grid_units(4) 0;
  padding-right: 10%;
  gap: grid_units(4);

  & > * {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

.fileInput {
  margin-top: grid_units(4);
}

.checkbox {
  margin-top: grid_units(4);
}

.divider {
  width: 100%;
  margin: grid_units(4) 0;
  border: 1px $blue-100 solid;
}

.bottomBar {
  display: flex;
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 100px;
  border-top: solid thin $grey-400;
  background-color: white;
}

.finishButton {
  margin-right: 138px;
}

.dateInput {
  width: 352px;
  input {
    width: 310px;
  }
}

.timeInput {
  width: 178px;
  display: flex;
  justify-content: space-between;
  button {
    width: 178px;
    &:focus,
    &:hover {
      border-color: $blue-400 !important;
    }
  }
}

.finishModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 820px;
  height: 500px;
  padding: grid_units(4);
  gap: grid_units(4);

  & > h4 {
    width: 50%;
    text-align: center;
  }
}

.modalButtonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: grid_units(4);

  & > div {
    width: 33%;
  }
}
