/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';

.relative {
  position: relative;
  width: 100%;
  height: fit-content;

  &--shrink {
    width: unset;
  }

  &[aria-invalid='true'] {
    .selectClass {
      border-color: $red-500;
    }
  }
}

.small {
  height: 40px;
}

.medium {
  height: 56px;
}

.selectClass {
  width: 100%;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  gap: 10px;
  border: 1px solid;
  border-color: $blue-300;
  border-radius: 4px;
  background-color: #ffffff;

  &:hover,
  &:focus {
    border-color: $blue-200;
  }

  &:disabled {
    border-color: $grey-300;
  }

  &[aria-expanded='true'] {
    border-color: $blue-400;
  }

  &--shrink {
    width: fit-content;
  }
}

.selectTitle {
  color: $blue-600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;

  // the sort is important!
  &--placeholder {
    color: $grey-300;
  }
  &--small {
    color: $blue-600;
  }
  &--disabled {
    color: $grey-300;
  }
}

.arrowContainer {
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 2px;
  pointer-events: none;

  svg {
    fill: $blue-600;

    &[aria-disabled='true'] {
      fill: $grey-300;
    }
  }

  .svgOnShow {
    transform: rotate(180deg);
  }

  &--disabled {
    color: $grey-300;
  }
}

.selectDropdown {
  position: absolute;
  z-index: 19;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  margin-top: 6px;
  max-height: 200px;
  min-width: max-content;
  overflow-y: auto;
  border-radius: 4px;

  &--right {
    right: 0;
  }
  &--left {
    left: 0;
  }
}

.hideDropdown {
  display: none;
}

.selectOption {
  box-sizing: border-box;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $blue-600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  width: 100%;
  text-align: left;
  outline: none;
  border: none;
  &[aria-selected='true'] {
    background-color: $grey-100;
  }
}

.withDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 2px;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  .titleEmoji {
    display: block;
    margin-right: $spaces-2;
  }

  .titleLabel {
    display: block;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
  }

  .optionDescription {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.errorMessage {
  position: absolute;
}
