@import 'src/styles/utils';
@import 'src/styles/palette';
@import 'src/styles/global';
@import 'src/styles/spaces';

.root {
  display: none;
  position: fixed;
  z-index: 21;
  top: 0;
  width: 100%;
  height: 100%;

  &--open {
    display: block;
  }
}

.modal {
  position: relative;
  z-index: 2;
  top: 50%;
  left: calc(50% - 40px);
  width: fit-content;
  height: fit-content;
  margin: 0 40px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: white;
}

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(13 20 27 / 32%);
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  margin-left: auto;
  padding: 0;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;
}
