@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';

.container {
  padding: grid_units(4);
}

.ownerDataTitle {
  margin: grid_units(2) 0;
}

.divider {
  width: 100%;
  margin: grid_units(4) 0;
  border: 1px $blue-100 solid;
}

.financialCheckInput {
  margin: grid_units(4) 0;
}

.financialCheckCheckbox {
  margin: grid_units(4) 0;
  input:disabled ~ label {
    span {
      border-color: $grey-300 !important;
      background-color: $grey-100 !important;
    }

    p {
      color: $grey-300 !important;
    }
  }
}

.propertyDataContainer {
  display: flex;
  flex-direction: row;

  & > img {
    width: 373px;
    height: 252px;
    margin-right: grid_units(4);
    border-radius: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }
}

.propertyData {
  margin-left: grid_units(4);
}

.currencyContainer {
  display: flex;
  align-items: baseline;
}

.propertyLink {
  color: $blue-600;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    color: $blue-400 !important;
    text-decoration: underline;
  }

  &:active {
    color: $blue-700;
    text-decoration: underline;
  }
}

.ownerOffer {
  & > div {
    width: calc(50% - grid_units(2));
  }
}

.contractTerms {
  display: grid;
  gap: grid_units(4);
  grid-template-columns: 1fr 1fr;
}

.card {
  position: relative;
  width: 330px;
  height: grid_units(33.5);
  transform: scale(1, 1);
  transition: transition_all(300);
  background: $white;
  cursor: pointer; // TODO: customize with a className
  border-radius: $spaces-2;
}

.cardClickable {
  width: 100%;
  padding: 0;
  border: none;
  background-color: $white;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  all: unset;
}

.banner {
  position: absolute;
  z-index: 10;
  top: grid_units(2);
  left: grid_units(2);
}

.sliderContainer {
  position: relative;
  .sliderContainerPaginator {
    display: flex;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 2px;
    left: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 11px 16px;
    border-radius: grid_units(2);
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 32%) 100%);
    color: white;
    gap: 6px;
  }
  .mainImage {
    border-width: 1px;
    border-style: solid;
    border-color: $grey-100;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  &:hover .mainImage {
    border-color: #c0c7ce;
  }

  &:active .mainImage {
    border-color: #96a2ad;
  }
}

.mainImageContainer {
  width: 100%;
  height: grid_units(33.5);
  border-top-left-radius: $spaces-2;
  border-top-right-radius: $spaces-2;
  border-bottom-right-radius: $spaces-2;
  border-bottom-left-radius: $spaces-2;
}

.mainImage {
  height: grid_units(33.5);
  border-top-left-radius: $spaces-2;
  border-top-right-radius: $spaces-2;
  border-bottom-right-radius: $spaces-2;
  border-bottom-left-radius: $spaces-2;
  object-fit: cover;
}

.sliderButton {
  display: inline-flex;
  position: absolute;
  z-index: 1;
  top: 50%;
  align-items: center;
  justify-content: center;
  padding: grid_units(1);
  transform: translateY(-50%);
  border-radius: 100%;
  background-color: #fff;

  &:hover {
    .sliderButtonIcon {
      opacity: 0.6;
    }
  }
}

.sliderButtonNext {
  right: grid_units(3);
}

.sliderButtonPrev {
  left: grid_units(3);
}

.sliderButtonNext,
.sliderButtonPrev {
  display: inline-flex !important;
}
