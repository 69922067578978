@import '../../../styles/utils';
@import '../../../styles/palette';

.frame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: grid_units(5);
  height: grid_units(5);
  border: none;
  border-radius: 50%;
  background-color: $white;
}

.clickable {
  cursor: pointer;
}

.small {
  width: grid_units(2);
  height: grid_units(2);
}

.bigArea {
  width: grid_units(20);
  height: grid_units(20);
  opacity: 0.7;
  filter: alpha(opacity=70); /* IE8 and lower */
}

.smallIcon {
  font-size: 10px;
}
