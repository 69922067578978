@import '../../../../styles/palette';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  color: $blue-600;
}

.data {
  color: $grey-600;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}
